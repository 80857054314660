/*------------------------------------------------------------------------------
| sign-in.js
| Device Scout v1.1
| Author(s): Richa Avasthi
| Created: 2011-07-06
|
| Operational JS for the sign-in page.
------------------------------------------------------------------------------*/

(function($) {
    $(function() {
        // Save the value in the email field for autofilling the forgot password form
        $("[data-forgot-password]").click(function() {
            var emailAddress = $("[data-login-email]").val().replace(/\s*/, '');
            if(emailAddress !== '')
            {
                window.location = this.href + "?email=" + escape(emailAddress);
                return false;
            }
            return true;
        });
    });
})(jQuery);
